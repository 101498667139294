import gsap from 'gsap'
import { forwardRef, useEffect, useRef, useState } from 'react'
import { classPrefix } from '../lib/util'
import { useGsapContext } from '../lib/hooks'

const AppIcon = forwardRef(function (props, ref) {
  return (
    <img src='/static/app-icon.png' className={classPrefix('hero__app-icon')} ref={ref} />
  )
})

function ScrollDownIcon () {
  return (
    <svg className='mw-100' width='50' height='91' viewBox='0 0 50 91' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='25' cy='65.8037' r='24' stroke='#404042' strokeWidth='2' />
      <path d='M26 2.80371C26 2.25143 25.5523 1.80371 25 1.80371C24.4477 1.80371 24 2.25143 24 2.80371L26 2.80371ZM24.2929 76.5108C24.6834 76.9013 25.3166 76.9013 25.7071 76.5108L32.0711 70.1469C32.4616 69.7563 32.4616 69.1232 32.0711 68.7326C31.6805 68.3421 31.0474 68.3421 30.6569 68.7326L25 74.3895L19.3431 68.7326C18.9526 68.3421 18.3195 68.3421 17.9289 68.7326C17.5384 69.1232 17.5384 69.7563 17.9289 70.1469L24.2929 76.5108ZM24 2.80371L24 75.8037L26 75.8037L26 2.80371L24 2.80371Z' fill='#404042' />
    </svg>
  )
}

function usePositionCalculation (callback) {
  const callbackRef = useRef()

  callbackRef.current = callback

  useEffect(() => {
    let cancel = false
    const update = () => {
      if (cancel) {
        return
      }
      callbackRef.current?.()
    }

    update()

    // Handling various global changes that might affect the positioning of an element
    // Note this doesn't include changes to the sizing of elements in the document made by javascript
    window.addEventListener('load', update)
    document.fonts?.ready?.then(update)
    window.addEventListener('resize', update)

    return () => {
      cancel = true
      window.removeEventListener('resize', update)
      window.removeEventListener('load', update)
    }
  }, [callbackRef])
}

function useScrollingEffects ({ scrollEnterTimelineRef, scrollExitTimelineRef }) {
  const containerRef = useRef()
  const logoRef = useRef()
  const logoPositionRef = useRef()
  const backdropRef = useRef()
  const copyRef = useRef()
  const splitLineRef = useRef()

  const [logoCentreOffset, setLogoCentreOffset] = useState(null)

  usePositionCalculation(() => {
    // Calculate the distance of the logo from the centre of the viewport. We use this to
    // centre the logo on the screen as part of the animation.
    const logoPosition = logoPositionRef.current.getBoundingClientRect()
    const containerPosition = containerRef.current.getBoundingClientRect()
    const centreOfContainer = containerPosition.height / 2
    const centreOfLogo = logoPosition.top - containerPosition.top + (logoPosition.height / 2)
    setLogoCentreOffset(centreOfContainer - centreOfLogo)
  })

  useGsapContext(() => {
    const enterTimeline = gsap.timeline()
      .from(copyRef.current, {
        opacity: 0,
        duration: 0.25,
        transform: 'translateY(20px)'
      }, 0.45)
      .from(logoRef.current, {
        opacity: 0,
        duration: 0.25,
        scale: 1.3
      }, 0.3)

    const centreAndCollapseLogo = gsap.timeline()
      .to(copyRef.current, {
        opacity: 0,
        duration: 0.2,
        translateY: 20,
        ease: 'expo.inOut'
      }, 0)
      .to(logoRef.current, {
        translateY: logoCentreOffset,
        duration: 0.4
      }, 0.05)
      .to(logoRef.current, {
        scale: 1.5,
        duration: 0.4,
        ease: 'power1.out'
      }, 0.45)
      .to(backdropRef.current, {
        scale: 0.005,
        duration: 0.8,
        ease: 'power3.inOut'
      }, 0.55)

    const expandSplit = gsap.timeline()
      .fromTo(splitLineRef.current, {
        height: 0,
        duration: 0.2,
        ease: 'expo.out'
      }, {
        height: '100vh'
      }, 0)
      .to(backdropRef.current, {
        opacity: 0,
        duration: 0
      }, 0.1)

    const logoFlyAway = gsap.timeline()
      .to(logoRef.current, {
        translateY: '-20vh',
        duration: 0.3
      }, 0)
      .to(logoRef.current, {
        opacity: 0,
        duration: 0.2
      }, 0.1)
      .to(splitLineRef.current, {
        '--x-scale': 1
      }, 0.1)

    const exitTimeline = gsap.timeline()
      .add(centreAndCollapseLogo, 0)
      .add(expandSplit, '+=0.1')
      .add(logoFlyAway, '+=0.1')

    // FIXME: misleading to not handle both forms of ref here
    scrollEnterTimelineRef(enterTimeline)
    scrollExitTimelineRef(exitTimeline)
  }, [containerRef, copyRef, backdropRef, logoRef, splitLineRef, scrollEnterTimelineRef, logoCentreOffset, logoPositionRef])

  return { containerRef, logoRef, backdropRef, copyRef, splitLineRef, logoPositionRef }
}

export default function HeroModule ({ title, intro, onScrollDown, scrollEnterTimelineRef, scrollExitTimelineRef }) {
  const { containerRef, logoRef, logoPositionRef, backdropRef, copyRef, splitLineRef } = useScrollingEffects({ scrollEnterTimelineRef, scrollExitTimelineRef })

  return (
    <div ref={containerRef} className={classPrefix('hero__container')}>
      <div className={classPrefix('hero__backdrop-position')}>
        <div className={classPrefix('hero__backdrop')} ref={backdropRef} />
      </div>
      <div className={classPrefix('hero__split-line')} ref={splitLineRef} />
      <div className='position-relative'>
        <div className={classPrefix('hero__logo-position')} ref={logoPositionRef}>
          <AppIcon ref={logoRef} />
        </div>
        <div ref={copyRef}>
          <h2 className={classPrefix('hero__title')}>{title}</h2>
          <p className={classPrefix('hero__intro-copy')}>
            {intro}
          </p>
          <button
            type='button'
            onClick={onScrollDown}
            className={classPrefix('hero__scroll-down')}
            aria-label='Scroll down to learn more'
          >
            <ScrollDownIcon />
          </button>
        </div>
      </div>
    </div>
  )
}
