import { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import VideoModule from '../video-module'
import DebugDrawer from './DebugDrawer'
import CONFIGS from './config'

function App () {
  const [selectedLanguage, setSelectedLanguage] = useState('en')
  const activeConfig = CONFIGS[selectedLanguage]

  const dir = selectedLanguage === 'ar' || selectedLanguage === 'he' ? 'rtl' : 'ltr'

  useEffect(() => {
    document.documentElement.lang = selectedLanguage
  }, [selectedLanguage])

  return (
    <>
      <DebugDrawer
        configs={CONFIGS}
        selectedLanguage={selectedLanguage}
        onSelectLanguage={setSelectedLanguage}
      />
      <div dir={dir}>
        <VideoModule {...activeConfig} />
      </div>
    </>
  )
}

const container = document.getElementById('app')
ReactDOM.render(<App />, container)
