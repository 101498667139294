import { useMemo, useRef, useState } from 'react'
import AudienceSelectorModule from './components/AudienceSelectorModule'
import HeroModule from './components/HeroModule'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import ScrollToPlugin from 'gsap/ScrollToPlugin'
import { classPrefix } from './lib/util'
import { useGsapContext } from './lib/hooks'

gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(ScrollToPlugin)

function mapAudiences (audiences) {
  console.assert(audiences.length === 2, 'Expected exactly two audiences')
  return audiences.map((audience) => {
    const { chapters, youtubeVideoID, label } = audience.fields
    return {
      chapters: chapters.map(chapter => ({
        label: chapter.fields.label.value,
        timeCode: chapter.fields.timeCode.value
      })),
      embedUrl: `https://www.youtube.com/embed/${youtubeVideoID.value}`,
      label: label.value
    }
  })
}

export default function BaseModule (
  /** @type {import("../test/types").ModuleProps} */
  { fields }
) {
  const scrollDownTargetRef = useRef()
  const [audienceSelectorScrollTimeline, setAudienceSelectorScrollTimeline] = useState(null)
  const [heroEnterScrollTimeline, setHeroEnterScrollTimeline] = useState(null)
  const [heroExitScrollTimeline, setHeroExitScrollTimeline] = useState(null)
  const scrollTrackRef = useRef()

  // Remaps the incoming fields from the CMS into the props components are expecting
  const heroTitle = fields.heroTitle.value
  const introCopy = fields.introCopy.value
  const audiences = useMemo(() => mapAudiences(fields.audiences), [fields.audiences])
  const sliderPromptLabel = fields.sliderPromptLabel.value

  const onScrollDown = () => {
    const target = window.scrollY + scrollDownTargetRef.current.getBoundingClientRect().top - window.innerHeight
    gsap.to(window, {
      duration: 4.5,
      ease: 'linear',
      scrollTo: {
        y: target,
        autoKill: false
      }
    })
  }

  useGsapContext(() => {
    if (audienceSelectorScrollTimeline == null || heroEnterScrollTimeline == null) {
      return
    }

    // These variables represent the proportion of vh elements allocated to each nested scroll animation. The total
    // screen height distance covered by the scroller is 600vh (the scroll rail is 600vh and it takes 100vh to initially
    // scroll it onto screen). These ratios should be modified if the height of the scroll-track is changed.
    // The elements' scroll effect timelines are normalised below using these values to make sure that they
    // cover the number of "screens" intended.
    const screenCount = 7
    const heroEnterScreenCount = 1
    const heroExitScreenCount = 3
    const selectorScreenCount = 1
    const paddingScreenCount = 1

    // FIXME: this is a hack that fixes a problem where only the hero timeline is re-initialised by a resize
    // event but the audience selector is not. Because the parent context is reverted by that change, the audience
    // selector is reverted as well, but it's never re-initialised which leaves it in a fully visible state until the
    // user scrolls past it. Scrubbing the timeline like this forces it to be invisibly re-initialised. There may well
    // be a proper call for reinitialising like this but I couldn't find it.
    audienceSelectorScrollTimeline.progress(1).progress(0)

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: scrollTrackRef.current,
        scrub: 1.5,
        snap: {
          snapTo: (value) => {
            // Do not allow the user to pause scrolling in the middle of the audience entry animation,
            // since the audience selector is not usable (pointer events disabled) until it's fully scrolled in.
            // const startAudienceEnterProgress = tl.labels.startAudienceEnter / tl.duration()
            const audienceEnteredProgress = tl.labels.audienceEntered / tl.duration()
            const audienceEnterProgress = tl.labels.audienceEnter / tl.duration()

            if (value >= audienceEnterProgress && value <= audienceEnteredProgress) {
              return audienceEnteredProgress
            }

            return value
          },
          inertia: false,
          delay: 0.1,
          duration: { min: 0.1, max: 3 },
          ease: 'power1.inOut'
        },
        start: 'top bottom',
        end: 'bottom bottom'
      }
    })
      // FIXME: we are modifying the timeline durations here, which happens quite far away in the code from
      // where the timeline was initialised and may be confusing. Perhaps a helper function to calculate these durations
      // should be passed down so the components can do it themselves?
      .add(heroEnterScrollTimeline.duration(heroEnterScreenCount / screenCount))
      .addLabel('audienceEnter', `+=${paddingScreenCount / screenCount}`)
      .add(heroExitScrollTimeline.duration(heroExitScreenCount / screenCount), `+=${paddingScreenCount / screenCount}`)
      .add(audienceSelectorScrollTimeline.duration(selectorScreenCount / screenCount))
      .addLabel('audienceEntered')

      // Hack: adds one screen's worth of padding at the end to make it harder to accidentally scroll past
      // the audience selector when moving quickly
      .add(() => {}, `+=${paddingScreenCount / screenCount}`)
  }, [audienceSelectorScrollTimeline, heroEnterScrollTimeline])

  return (
    <>
      <div className={classPrefix('scroll-track')} ref={scrollTrackRef}>
        <div className={classPrefix('scroller')}>
          <HeroModule
            title={heroTitle}
            intro={introCopy}
            onScrollDown={onScrollDown}
            scrollEnterTimelineRef={setHeroEnterScrollTimeline}
            scrollExitTimelineRef={setHeroExitScrollTimeline}
          />
          <div className='position-absolute top-0 start-0 bottom-0 end-0' style={{ pointerEvents: 'none' }}>
            <AudienceSelectorModule
              scrollTimelineRef={setAudienceSelectorScrollTimeline}
              audiences={audiences}
              sliderPromptLabel={sliderPromptLabel}
            />
          </div>
        </div>
      </div>
      <div ref={scrollDownTargetRef} />
    </>
  )
}
