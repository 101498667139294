import { forwardRef } from 'react'
import { classPrefix } from '../../lib/util'
import { clamp, normalise } from '../../lib/math'
import PaneVideo from './PaneVideo'

const AudiencePane = forwardRef(function ({
  size,
  selected,
  videoDetails,
  disablePointerEvents,
  audienceId,
  audienceName,
  imgSrc,
  onClick,
  onActivate,
  onDeactivate
}, ref) {
  const deemphasise = clamp(normalise(size, 50, 30), 0, 1)
  const emphasise = clamp(normalise(size, 50, 90), 0, 1)
  const tintColour = [
    // teal
    '61, 100, 116',
    // purple
    '99, 86, 109'
  ][audienceId]
  const tintBackground = `rgba(${tintColour}, ${selected ? 1 : 0.5 + deemphasise * 0.45})`

  return (
    <div
      ref={ref}
      className='position-relative overflow-hidden'
    >
      <div
        className='w-100 h-100'
        style={{
          filter: `grayscale(${Math.abs(deemphasise) * 100}%)`,
          backgroundImage: imgSrc ? `url(${imgSrc})` : 'none',
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      >
        <div
          className='w-100 h-100 d-flex align-items-center justify-content-center'
          style={{
            backgroundColor: tintBackground,
            cursor: 'pointer',
            pointerEvents: disablePointerEvents ? 'none' : 'auto'
          }}
        >
          {/*
          This is a special target area for clicks and mouse *exit* events only. We use a much smaller area around the text label
          to activate the mouse *enter*. Using a small area to trigger the enter signals clearer user intention but avoids a feedback
          wobble effect when the label moves after being hovered.
        */}
          <div
            className='position-absolute w-100 h-100'
            style={{
              [audienceId === 0 ? 'paddingRight' : 'paddingLeft']: '20%'
            }}
          >
            <div
              className='w-100 h-100'
              onMouseLeave={onDeactivate}
              onClick={onClick}
            />
          </div>

          {/* Target area for the activating "enter" event */}
          <button
            className={classPrefix('audience-selector__audience-button')}
            onFocus={onActivate}
            tabIndex={!selected ? 0 : -1}
            onMouseEnter={onActivate}
            onClick={onClick}
          >
            <div
              style={{
                zIndex: 1,
                opacity: 1 - deemphasise,
                transform: `scale(${1 + emphasise * 0.2})`
              }}
            >
              <div
                style={{
                  transform: `translateY(${selected ? -20 : 0}px)`,
                  opacity: selected ? 0 : 1,
                  transition: 'transform 0.3s ease-out, opacity 0.2s ease-out'
                }}
              >
                <h2 className={classPrefix('audience-selector__audience-name')}>
                  {audienceName}
                </h2>
              </div>
            </div>
          </button>
        </div>
      </div>
      {selected && (
        <div className='position-absolute start-0 top-0 w-100 h-100' style={{ zIndex: '1' }}>
          <PaneVideo audienceId={audienceId} {...videoDetails} />
        </div>
      )}
    </div>
  )
})

export default AudiencePane
