export function clamp (value, min, max) {
  return Math.min(Math.max(value, min), max)
}

export function normalise (value, min, max) {
  return (value - min) / (max - min)
}

export function lerp (start, end, t) {
  if (Math.abs(start - end) < 0.001) {
    return end
  }
  return start * (1 - t) + end * t
}
