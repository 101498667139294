import gsap from 'gsap'
import { useCallback, useEffect, useRef, useState } from 'react'

export function useTimeoutWhen (callback, delay, condition) {
  const callbackRef = useRef()

  callbackRef.current = callback

  useEffect(() => {
    if (!condition) {
      return
    }

    const timeout = setTimeout(() => {
      callbackRef.current()
    }, delay)

    return () => {
      clearTimeout(timeout)
    }
  }, [delay, condition])
}

export function useRaf (callback) {
  const requestRef = useRef()

  const animate = useCallback(() => {
    callback()
    requestRef.current = window.requestAnimationFrame(animate)
  }, [callback])

  useEffect(() => {
    requestRef.current = window.requestAnimationFrame(animate)
    return () => window.cancelAnimationFrame(requestRef.current)
  }, [animate])
}

export function useIsMobile () {
  const [isMobile, setIsMobile] = useState(null)

  useEffect(() => {
    const mql = window.matchMedia('(max-width: 768px)')
    const update = () => {
      setIsMobile(mql.matches)
    }

    mql.addEventListener('change', update)
    update()

    return () => {
      mql.removeEventListener('change', update)
    }
  }, [])

  return isMobile
}

export function useGsapContext (callback, deps = []) {
  useEffect(() => {
    const ctx = gsap.context(callback)
    return () => {
      ctx.revert()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}

export function useDocumentLanguage () {
  const [documentLanguage, setDocumentLanguage] = useState(null)

  useEffect(() => {
    setDocumentLanguage(document.documentElement.lang)
  }, [])

  return documentLanguage
}
