export default function DebugDrawer ({ configs, selectedLanguage, onSelectLanguage }) {
  const languages = Object.keys(configs)

  return (
    <div className='position-absolute top-0 end-0 m-4 bg-white p-4'>
      <h1>Debug</h1>
      <div>
        <label className='me-2'>Language</label>
        <select
          value={selectedLanguage}
          onChange={e => onSelectLanguage(e.target.value)}
        >
          {languages.map(lang => (
            <option key={lang} value={lang}>
              {lang}
            </option>
          ))}
        </select>
      </div>
    </div>
  )
}
