export function classPrefix (...args) {
  return args.map(className => `nucleus-video-module__${className}`).join(' ')
}

export function delay (duration) {
  return new Promise(resolve => {
    setTimeout(resolve, duration)
  })
}

export function timecodeToSeconds (timecode) {
  if (!timecode.match(/^\d{2}:\d{2}$/)) {
    throw new Error(`Invalid timecode: ${timecode}`)
  }
  const [minutes, seconds] = timecode.split(':').map(Number)
  return (minutes * 60) + seconds
}
