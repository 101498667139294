import gsap from 'gsap'
import { forwardRef, useRef, useState } from 'react'
import { useDocumentLanguage, useGsapContext } from '../../lib/hooks'
import ReactPlayer from 'react-player'
import { classPrefix, timecodeToSeconds } from '../../lib/util'
import classNames from 'classnames'

function PlayIcon () {
  return (
    <svg width='27' height='28' viewBox='0 0 27 28' fill='none' xmlns='http://www.w3.org/2000/svg' aria-hidden='true'>
      <path d='M18.8181 13.4375C19.4848 13.8224 19.4848 14.7847 18.8181 15.1696L11.5908 19.3422C10.9242 19.7271 10.0908 19.246 10.0908 18.4762L10.0908 10.1309C10.0908 9.36106 10.9242 8.87994 11.5908 9.26484L18.8181 13.4375Z' fill='currentColor' />
      <circle cx='13.5' cy='14.3037' r='12.5' transform='rotate(90 13.5 14.3037)' stroke='currentColor' strokeWidth='2' />
    </svg>
  )
}

const ChapterButton = forwardRef(function ({ title, active, timeCode, onClick }, ref) {
  return (
    <button
      className={classNames(
        classPrefix('audience-selector__chapter-button'),
        { 'is-active': active }
      )}
      onClick={onClick}
      ref={ref}
      aria-current={active ? 'time' : null}
      aria-label={`Skip to "${title}" at ${timeCode}`}
    >
      <div className={classPrefix('audience-selector__chapter-button-title')}>
        {title}
      </div>
      <div className='mt-auto'>
        {timeCode}
      </div>
      <PlayIcon />
    </button>
  )
})

function usePaneVideoEnter () {
  const playerWrapperRef = useRef()
  const controlsRef = useRef()

  useGsapContext(() => {
    gsap.timeline()
      .from(playerWrapperRef.current, {
        opacity: 0,
        duration: 0.5
      }, 0.5)
      .from(controlsRef.current, {
        duration: 0.5,
        opacity: 0
      }, '<')
  })

  return { playerWrapperRef, controlsRef }
}

export default function PaneVideo ({ audienceId, embedUrl, chapters }) {
  const [playing, setPlaying] = useState(true)
  const playerRef = useRef()
  const [currentChapterIndex, setCurrentChapterIndex] = useState(null)
  const { playerWrapperRef, controlsRef } = usePaneVideoEnter()
  const documentLanguage = useDocumentLanguage()

  return (
    <div className='w-100 h-100 position-relative'>
      <div className='d-flex flex-column h-100 justify-content-stretch'>
        <div className='flex-grow-1 position-relative'>
          <div className={classPrefix('audience-selector__video-wrapper')} ref={playerWrapperRef}>
            <div className={classPrefix('audience-selector__video-sizer')}>
              <ReactPlayer
                url={embedUrl}
                playing={playing}
                width='100%'
                height='100%'
                controls
                progressInterval={100}
                ref={playerRef}
                config={{
                  youtube: {
                    playerVars: {
                      rel: 0,
                      color: 'white',
                      // FIXME: I wasn't able to find any clear documentation on how YT embeds handle the user's browser
                      // language preference so I'm trying to insist on the subtitles matching the document language
                      // here.
                      cc_lang_pref: documentLanguage,
                      cc_load_policy: 1
                    }
                  }
                }}
                onPause={() => {
                  setPlaying(false)
                }}
                onProgress={({ playedSeconds }) => {
                  if (playing) {
                  // From the end of the chapter list, work our way back till we find a chapter that's been played; that's the chapter we're at currently.
                    const furthestChapterPassed = [...chapters].reverse().find(({ timeCode }) => playedSeconds >= timecodeToSeconds(timeCode))
                    const chapterIndex = chapters.indexOf(furthestChapterPassed)
                    setCurrentChapterIndex(chapterIndex)
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div
          className='flex-grow-0'
          ref={controlsRef}
        >
          <div className={classNames(
            classPrefix('audience-selector__chapters'),
            classPrefix(`audience-selector__chapters--audience-${audienceId}`)
          )}
          >
            {chapters.map(({ label, timeCode }, chapterIndex) => (
              <ChapterButton
                key={timeCode}
                title={label}
                timeCode={timeCode}
                active={currentChapterIndex === chapterIndex}
                onClick={() => {
                  setPlaying(true)
                  playerRef.current.seekTo(timecodeToSeconds(timeCode))
                }}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
