/** @type {Record<string, import("./types").ModuleProps>} */
const CONFIGS = {
  en: {
    uid: '2e8eeff0-74c3-4393-b6e9-fe2674d64330',
    componentName: 'RebrandSmartAppDemo',
    dataSource: '{23A5D872-B0F6-45BD-AF75-608930541582}',
    params: {},
    fields: {
      audiences: [
        {
          id: '3ce6fbe7-d2db-4d69-af15-d0097bf4bb82',
          url: '/global/en/apps/smart-app-demo/data-sources/smart-app-demo/adults',
          name: 'Adults',
          displayName: 'Adults',
          fields: {
            chapters: [
              {
                id: '1e0e7a84-7b6e-4b07-a665-c858485e4d7f',
                url: '/global/en/apps/smart-app-demo/data-sources/smart-app-demo/adults/1---connect-in-noise',
                name: '1 - Connect in noise',
                displayName: '1 - Connect in noise',
                fields: {
                  timeCode: { value: '00:00' },
                  label: { value: 'Connect in noise' }
                }
              },
              {
                id: '0656c88c-7265-487c-bac5-f348c905a66f',
                url: '/global/en/apps/smart-app-demo/data-sources/smart-app-demo/adults/2---connect-to-your-world',
                name: '2 - Connect to your world',
                displayName: '2 - Connect to your world',
                fields: {
                  timeCode: { value: '00:20' },
                  label: { value: 'Connect to your world' }
                }
              },
              {
                id: '5b11f30b-9d08-4c9a-8b74-38f37c1ddef0',
                url: '/global/en/apps/smart-app-demo/data-sources/smart-app-demo/adults/3---connect-every-day',
                name: '3 - Connect every day',
                displayName: '3 - Connect every day',
                fields: {
                  timeCode: { value: '00:40' },
                  label: { value: 'Connect every day' }
                }
              },
              {
                id: 'addb50b9-5d74-4643-bacd-bae7d4cc83f5',
                url: '/global/en/apps/smart-app-demo/data-sources/smart-app-demo/adults/4---connect-to-care',
                name: '4 - Connect to care',
                displayName: '4 - Connect to care',
                fields: {
                  timeCode: { value: '01:00' },
                  label: { value: 'Connect to care' }
                }
              }
            ],
            youtubeVideoID: { value: 'f0IOLUiO-t0' },
            label: { value: 'Adults' }
          }
        },
        {
          id: 'f8afcc8c-7eaa-4b54-ae8c-568a906bcf7b',
          url: '/global/en/apps/smart-app-demo/data-sources/smart-app-demo/children',
          name: 'Children',
          displayName: 'Children',
          fields: {
            chapters: [
              {
                id: '3382f943-2586-44e7-9dd9-07410bfd6e9a',
                url: '/global/en/apps/smart-app-demo/data-sources/smart-app-demo/children/1---connect-to-their-world',
                name: '1 - Connect to their world',
                displayName: '1 - Connect to their world',
                fields: {
                  timeCode: { value: '00:00' },
                  label: { value: 'Connect to their world' }
                }
              },
              {
                id: '343ebdd0-79be-430d-9735-76fc4795789f',
                url: '/global/en/apps/smart-app-demo/data-sources/smart-app-demo/children/2---connect-every-day',
                name: '2 - Connect every day',
                displayName: '2 - Connect every day',
                fields: {
                  timeCode: { value: '00:08' },
                  label: { value: 'Connect every day' }
                }
              },
              {
                id: '7b161af7-ea39-4dc0-98f9-2160a9d64d08',
                url: '/global/en/apps/smart-app-demo/data-sources/smart-app-demo/children/3---connect-to-care',
                name: '3 - Connect to care',
                displayName: '3 - Connect to care',
                fields: {
                  timeCode: { value: '00:12' },
                  label: { value: 'Connect to care' }
                }
              }
            ],
            youtubeVideoID: { value: 'f-3_MI-GI6E' },
            label: { value: 'Children' }
          }
        }
      ],
      introCopy: {
        value: 'When you choose Cochlear, you’ll have convenient access to care and support in everyday moments with the Nucleus Smart App.'
      },
      sliderPromptLabel: { value: 'Tag and drag to explore' },
      heroTitle: { value: 'Nucleus Smart App' },
      'css classes': { value: '' },
      'margin-top': { value: '' },
      'margin-bottom': { value: '' },
      'padding-top': { value: '' },
      'padding-bottom': { value: '' }
    }
  },
  es: {
    uid: '2e8eeff0-74c3-4393-b6e9-fe2674d64330',
    componentName: 'RebrandSmartAppDemo',
    dataSource: '{23A5D872-B0F6-45BD-AF75-608930541582}',
    params: {},
    fields: {
      audiences: [
        {
          id: '3ce6fbe7-d2db-4d69-af15-d0097bf4bb82',
          url: '/global/en/apps/smart-app-demo/data-sources/smart-app-demo/adults',
          name: 'Adults',
          displayName: 'Adults',
          fields: {
            chapters: [
              {
                id: '1e0e7a84-7b6e-4b07-a665-c858485e4d7f',
                url: '/global/en/apps/smart-app-demo/data-sources/smart-app-demo/adults/1---connect-in-noise',
                name: '1 - Connect in noise',
                displayName: '1 - Conectar en ruido',
                fields: {
                  timeCode: { value: '00:00' },
                  label: { value: 'Conectar en ruido' }
                }
              },
              {
                id: '0656c88c-7265-487c-bac5-f348c905a66f',
                url: '/global/en/apps/smart-app-demo/data-sources/smart-app-demo/adults/2---connect-to-your-world',
                name: '2 - Connect to your world',
                displayName: '2 - Conectar con tu mundo',
                fields: {
                  timeCode: { value: '00:20' },
                  label: { value: 'Conectar con tu mundo' }
                }
              },
              {
                id: '5b11f30b-9d08-4c9a-8b74-38f37c1ddef0',
                url: '/global/en/apps/smart-app-demo/data-sources/smart-app-demo/adults/3---connect-every-day',
                name: '3 - Connect every day',
                displayName: '3 - Conectar todos los días',
                fields: {
                  timeCode: { value: '00:40' },
                  label: { value: 'Conectar todos los días' }
                }
              },
              {
                id: 'addb50b9-5d74-4643-bacd-bae7d4cc83f5',
                url: '/global/en/apps/smart-app-demo/data-sources/smart-app-demo/adults/4---connect-to-care',
                name: '4 - Connect to care',
                displayName: '4 - Conectar con el cuidado',
                fields: {
                  timeCode: { value: '01:00' },
                  label: { value: 'Conectar con el cuidado' }
                }
              }
            ],
            youtubeVideoID: { value: 'f0IOLUiO-t0' },
            label: { value: 'Adultos' }
          }
        },
        {
          id: 'f8afcc8c-7eaa-4b54-ae8c-568a906bcf7b',
          url: '/global/en/apps/smart-app-demo/data-sources/smart-app-demo/children',
          name: 'Children',
          displayName: 'Niños',
          fields: {
            chapters: [
              {
                id: '3382f943-2586-44e7-9dd9-07410bfd6e9a',
                url: '/global/en/apps/smart-app-demo/data-sources/smart-app-demo/children/1---connect-to-their-world',
                name: '1 - Connect to their world',
                displayName: '1 - Conectar con su mundo',
                fields: {
                  timeCode: { value: '00:00' },
                  label: { value: 'Conectar con su mundo' }
                }
              },
              {
                id: '343ebdd0-79be-430d-9735-76fc4795789f',
                url: '/global/en/apps/smart-app-demo/data-sources/smart-app-demo/children/2---connect-every-day',
                name: '2 - Connect every day',
                displayName: '2 - Conectar todos los días',
                fields: {
                  timeCode: { value: '00:08' },
                  label: { value: 'Conectar todos los días' }
                }
              },
              {
                id: '7b161af7-ea39-4dc0-98f9-2160a9d64d08',
                url: '/global/en/apps/smart-app-demo/data-sources/smart-app-demo/children/3---connect-to-care',
                name: '3 - Connect to care',
                displayName: '3 - Conectar con el cuidado',
                fields: {
                  timeCode: { value: '00:12' },
                  label: { value: 'Conectar con el cuidado' }
                }
              }
            ],
            youtubeVideoID: { value: 'f-3_MI-GI6E' },
            label: { value: 'Niños' }
          }
        }
      ],
      introCopy: {
        value: 'Cuando elijas Cochlear, tendrás acceso conveniente al cuidado y apoyo en momentos cotidianos con la aplicación Nucleus Smart.'
      },
      sliderPromptLabel: { value: 'Etiqueta y arrastra para explorar' },
      heroTitle: { value: 'Aplicación Nucleus Smart' },
      'css classes': { value: '' },
      'margin-top': { value: '' },
      'margin-bottom': { value: '' },
      'padding-top': { value: '' },
      'padding-bottom': { value: '' }
    }
  },
  ar: {
    uid: '2e8eeff0-74c3-4393-b6e9-fe2674d64330',
    componentName: 'RebrandSmartAppDemo',
    dataSource: '{23A5D872-B0F6-45BD-AF75-608930541582}',
    params: {},
    fields: {
      audiences: [
        {
          id: '3ce6fbe7-d2db-4d69-af15-d0097bf4bb82',
          url: '/global/en/apps/smart-app-demo/data-sources/smart-app-demo/adults',
          name: 'الكبار',
          displayName: 'الكبار',
          fields: {
            chapters: [
              {
                id: '1e0e7a84-7b6e-4b07-a665-c858485e4d7f',
                url: '/global/en/apps/smart-app-demo/data-sources/smart-app-demo/adults/1---connect-in-noise',
                name: '1 - الاتصال في الضوضاء',
                displayName: '1 - الاتصال في الضوضاء',
                fields: {
                  timeCode: { value: '00:00' },
                  label: { value: 'الاتصال في الضوضاء' }
                }
              },
              {
                id: '0656c88c-7265-487c-bac5-f348c905a66f',
                url: '/global/en/apps/smart-app-demo/data-sources/smart-app-demo/adults/2---connect-to-your-world',
                name: '2 - الاتصال بعالمك',
                displayName: '2 - الاتصال بعالمك',
                fields: {
                  timeCode: { value: '00:20' },
                  label: { value: 'الاتصال بعالمك' }
                }
              },
              {
                id: '5b11f30b-9d08-4c9a-8b74-38f37c1ddef0',
                url: '/global/en/apps/smart-app-demo/data-sources/smart-app-demo/adults/3---connect-every-day',
                name: '3 - الاتصال كل يوم',
                displayName: '3 - الاتصال كل يوم',
                fields: {
                  timeCode: { value: '00:40' },
                  label: { value: 'الاتصال كل يوم' }
                }
              },
              {
                id: 'addb50b9-5d74-4643-bacd-bae7d4cc83f5',
                url: '/global/en/apps/smart-app-demo/data-sources/smart-app-demo/adults/4---connect-to-care',
                name: '4 - الاتصال بالرعاية',
                displayName: '4 - الاتصال بالرعاية',
                fields: {
                  timeCode: { value: '01:00' },
                  label: { value: 'الاتصال بالرعاية' }
                }
              }
            ],
            youtubeVideoID: { value: 'f0IOLUiO-t0' },
            label: { value: 'الكبار' }
          }
        },
        {
          id: 'f8afcc8c-7eaa-4b54-ae8c-568a906bcf7b',
          url: '/global/en/apps/smart-app-demo/data-sources/smart-app-demo/children',
          name: 'الأطفال',
          displayName: 'الأطفال',
          fields: {
            chapters: [
              {
                id: '3382f943-2586-44e7-9dd9-07410bfd6e9a',
                url: '/global/en/apps/smart-app-demo/data-sources/smart-app-demo/children/1---connect-to-their-world',
                name: '1 - الاتصال بعالمهم',
                displayName: '1 - الاتصال بعالمهم',
                fields: {
                  timeCode: { value: '00:00' },
                  label: { value: 'الاتصال بعالمهم' }
                }
              },
              {
                id: '343ebdd0-79be-430d-9735-76fc4795789f',
                url: '/global/en/apps/smart-app-demo/data-sources/smart-app-demo/children/2---connect-every-day',
                name: '2 - الاتصال كل يوم',
                displayName: '2 - الاتصال كل يوم',
                fields: {
                  timeCode: { value: '00:08' },
                  label: { value: 'الاتصال كل يوم' }
                }
              },
              {
                id: '7b161af7-ea39-4dc0-98f9-2160a9d64d08',
                url: '/global/en/apps/smart-app-demo/data-sources/smart-app-demo/children/3---connect-to-care',
                name: '3 - الاتصال بالرعاية',
                displayName: '3 - الاتصال بالرعاية',
                fields: {
                  timeCode: { value: '00:12' },
                  label: { value: 'الاتصال بالرعاية' }
                }
              }
            ],
            youtubeVideoID: { value: 'f-3_MI-GI6E' },
            label: { value: 'الأطفال' }
          }
        }
      ],
      introCopy: {
        value: 'عندما تختار Cochlear، ستحصل على وصول مريح إلى الرعاية والدعم في اللحظات اليومية مع تطبيق Nucleus Smart.'
      },
      sliderPromptLabel: { value: 'سحب ووسم للاستكشاف' },
      heroTitle: { value: 'تطبيق Nucleus الذكي' },
      'css classes': { value: '' },
      'margin-top': { value: '' },
      'margin-bottom': { value: '' },
      'padding-top': { value: '' },
      'padding-bottom': { value: '' }
    }
  }
}

export default CONFIGS
